.navButton{
    background-color: white;
    width: 12%;
    display: flex;
    justify-content: center;
    margin-top: 1%;
    border-radius: 15px;
    padding: 2px;
    height: auto;
    font-weight: 600;
    color: #ff0f64;
    margin-bottom: 10px;
}
@media screen and (max-width: 600px)  {
    .navButton{
        width: fit-content;
    }
}

.navButton:hover{
    transform: perspective(1px) scale3d(1.044, 1.044, 1) translateZ(0);
}