.grid{
    height: 550px;
    width:  95%;
    background-color: white;
    margin: auto;
    margin-top: 0;
    margin-bottom: 0;
}

.clusterTag{
    margin-top: 15px;
    margin-right: auto;
    margin-left: 3%;
    width: 240px;
    text-align: center;
    background-color: #ff0f64;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    font-size: 18px;
    color: white;
    padding: 5px;
}

.clusterValue{
    border-radius: 12px;
    color: grey;
    background-color: white;
    padding: 6px;
    width: 90%;
}

.InputContainerOF{
    display: flex;
    column-gap: 20px;
    flex-direction: row ;
    background-color: white ;
    width: 100% ;
    justify-content: center;
    margin:0;
    height: 80px;
    align-items: center; 
    padding-left: 5px;
}
#cityContainerOF{
    margin-left: 10px;
}
.textContainer{
    width: 90%;
}
.FieldsContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.ButtonsContainer{
    display: flex;
    width: auto;
    margin-right: 3%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 45px;
}
#Export{
    height: 90%;
    padding-top: 4px;
}
#LoadMore{
    height: 90%;
    padding-top: 4px;
}

@media screen and (max-width: 600px) {
    #cityContainerOF{
        margin-top: 10px;
        margin-left: 0px;
    }
    .InputContainerOF{
        flex-direction: column;
        height: 100%;
        row-gap: 10px;
        align-items: center;
        justify-content: center;
    }
    .textContainer{
        width: 70%;
    }
    .btn{
        width: 70%;
        margin-bottom: 10px;
        padding-top: 10px;
        text-align: center;
    }
    #Export{
        padding: 0;
    }
    #LoadMore{
        padding: 0;
    }
    .ButtonsContainer{
        height: 100%;
        width: 100%;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 10px;
        margin-right: 0;
    }
   
}
@media screen and (max-width: 600px) {
    #region{
        width: 100vw;
        margin: 0;
        padding: 0;
        margin-left: 10%;
    }    
}
